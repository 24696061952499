import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import { IonSpinner } from '@ionic/react';
import forms from '../../utils/forms';
import dl from '../../utils/device-language';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useFilters } from '../../utils/contexts/filters-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useUser } from '../../utils/contexts/user-context';
import coursesClient from '../../logic/courses';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Products from './Products';

export default function Row(props) {
    const { course, index } = props;

    const { type } = useParams();
    const { i18n } = useTranslation();
    const { trackDataFn } = useTracker();
    const { user } = useUser();
    const { restaurant } = useRestaurant();
    const { filters, filtered } = useFilters();

    const [open, setOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [products, setProducts] = React.useState([]);

    async function onOpening() {
        // populate products with course products
        // products are loaded from courses logic
        await trackDataFn(restaurant.id, restaurant.token, "courseopen", {
            "type": type,
            "name": this.trigger.props.trigger.name,
            "id": course.id,
        }, restaurant.has_orders);

        let clientCourse;
        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();

        const { allergies, foods, diets } = forms.parseFilters(filters);

        if (user) {
            if (filtered) {
                clientCourse = await coursesClient.doGetUserFilters(language, course.id, user.user_token);
            } else {
                clientCourse = await coursesClient.doGet(language, course.id);
            }
        } else {
            if (allergies || foods || diets) {
                clientCourse = await coursesClient.doGetFilters(language, course.id, allergies, foods, diets);
            } else {
                clientCourse = await coursesClient.doGet(language, course.id);
            }
        }

        setProducts(clientCourse.dishes);
        setLoading(false);
    }

    React.useEffect(() => {
        setProducts([]);
        setOpen(false);
        setLoading(true);
    }, [filters]);

    return (
        <Collapsible
            className="row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened setOpen={setOpen}></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots"></IonSpinner> :
                    <Products products={products} index={index}></Products>}
            </div>
        </Collapsible>
    );
}
