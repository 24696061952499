import dl from "./device-language";

function isEven(value) {
    return (Number(value) % 2 === 0) ? true : false;
}

function shouldDisplayCta(type, apiResponse) {
    if (type === "r" || type === "h" || type === "s") {
        return apiResponse.tableservice.active;
    } else if ((type === "d" || type === "t") && (Boolean(apiResponse.delivery.active) === true || Boolean(apiResponse.takeaway.active) === true)) {
        return true;
    } else if (type === "b") {
        return true;
    }
}

function shouldDisplayDishDetails(dish) {
    return dish ?
        (dish.hasOwnProperty("description") && ("" !== dish.description)) ||
        (dish.hasOwnProperty("ingredients") && (0 < dish.ingredients.length)) :
        false;
}

function shouldDisplayDrinkDetails(drink) {
    return drink ?
        (drink.hasOwnProperty("description") && ("" !== drink.description)) ||
        (drink.hasOwnProperty("image_path") && ("" !== drink.image_path)) :
        false;
}

function shouldDisplayRestaurantCoverCharge(restaurant, type) {
    return restaurant ?
        restaurant.hasOwnProperty("cover_charge") && Boolean(restaurant.cover_charge) && type === "r" :
        false;
}

function shouldDisplayPrice(item, field) {
    return item ?
        item.hasOwnProperty(field) && item[field] :
        false;
}

function shouldDisplayPayments(restaurant) {
    let check = restaurant.hasOwnProperty("payments") && restaurant.payments
        .filter(payment => "cash" !== payment.name)
        .filter(payment => payment.active === true);

    return Boolean(check.length);
}

function shouldDisplayPaymentsV2(restaurant, type) {
    if (type === "restaurant") {
        type = "tableservice";
    }

    let check = restaurant.orders[type].hasOwnProperty("payment_methods") && restaurant.orders[type].payment_methods
        .filter(payment => "cash" !== payment.name)

    return Boolean(check.length);
}

function shouldDisplayPaymentsButton(restaurant, name) {
    if (restaurant) {
        if (restaurant.hasOwnProperty("payments")) {
            let check = restaurant.payments.filter(payment => payment.name === name);

            if (check.length > 0) {
                return check[0].active;
            }
        }
    }
    return false;
}

function shouldDisplayPaymentsButtonV2(restaurant, name, type) {
    if (type === "restaurant") {
        type = "tableservice";
    }

    if (restaurant) {
        if (restaurant.orders[type].hasOwnProperty("payment_methods")) {
            let check = restaurant.orders[type].payment_methods.filter(payment => payment.name === name);
            if (check.length > 0) {
                return true;
            }
        }
    }
    return false;
}

function shouldDisplayFilterIcon(element) {
    if (element && element.hasOwnProperty("icon") && element.icon !== "") { // element.icon != "" && element.icon != null - 0 != "" gives false
        return true;
    }
}

function capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function cleanPrice(price) {
    price = Number(price);
    return price && !isNaN(price) ? price : "";
}

function formatPrice(price, risId, locale) {
    let options = {
        "style": "currency",
        "currency": (risId && Number(risId) === 14926) ? "AED" : "EUR",
        "minimumFractionDigits": 2,
    };

    return new Intl.NumberFormat(locale ? locale : dl.getDeviceLanguage(), options).format(price);

}

function formatUrl(url, obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const token = `:${key}`;
            url = String(url).replace(token, obj[key]);
        }
    }
    return url;
}

function formatIngredients(ingredients) {
    let mapped = ingredients.map((ingredient) => {
        let name = ingredient.name;

        name += ingredient.has_allergens ?
            " (" + ingredient.allergens.map(allergen => allergen.name).join(", ") + ")" :
            "";

        return capitalize(name);
    });

    return mapped.join(", ");
}

function formatDrinkName(drink) {
    let name = capitalize(drink.name);

    name += !!drink.cellar ? ` (${drink.cellar})` : "";
    name += !!drink.year ? ` (${drink.year})` : "";

    return name;
}

function formatDescription(item) {
    let originalDescription = item.hasOwnProperty("description") ?
        String(item.description) : "";

    return originalDescription.length > 100 ?
        originalDescription.substr(0, 97) + "..." :
        originalDescription;
}

function formatMotuekaDescription(description) {
    return description.split(/-/gi);
}

function truncate(text, length) {
    if (text.length > length) {
        return text.substr(0, length) + "...";
    } else {
        return text;
    }
}

function getDayOfWeek(Day) {
    const Days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return Days.indexOf(Day);
}

function shiftTime(dt, minutesShift) {
    let date = new Date(dt.getTime() + minutesShift * 60000);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 10) {
        minutes = "0" + (minutes.toString());
    }
    return { hours, minutes };
}

function formatIsoTime(dt) {
    let aux = dt.toISOString();
    let newTime = [];
    let twotimes = 0;
    for (let i = 0; i < aux.length && twotimes < 2; i++) {
        newTime.push(aux[i]);
        if (aux[i] === ":") {
            twotimes = twotimes + 1;
        }
        if (Number(twotimes) === 2) {
            newTime.pop();
        }
    }
    newTime = newTime.join("");

    return newTime;
}

function getDeliveryRangeCost(restaurant) {
    let minCost, maxCost = null;

    const distanceFares = restaurant.orders.delivery.distance_fares.map((distanceRange, i) =>
        distanceRange.cost_increase
    )

    if (distanceFares.length > 0) {
        minCost = Math.min.apply(Math, distanceFares);
        maxCost = Math.max.apply(Math, distanceFares);
    }

    return { minCost, maxCost };
}

function setMorganaMenu(morganaRestaurant) {
    const idxToStart = morganaRestaurant.courses.findIndex(course => course.name === "dessert");
    morganaRestaurant.wrapCourses = [];
    let aux = [];
    aux.sushi = [];
    aux.cucina = [];
    aux.dessert = [];

    morganaRestaurant.courses.forEach((course, i) => {
        if (i === idxToStart) {
            aux.dessert.push(course);
        } else if (i > idxToStart - 1) {
            aux.sushi.push(course);
        } else {
            aux.cucina.push(course);
        }
    })

    morganaRestaurant.wrapCourses.push(aux.cucina);
    morganaRestaurant.wrapCourses.push(aux.sushi);
    morganaRestaurant.wrapCourses.push(aux.dessert);
    morganaRestaurant.wrapCourses.push(morganaRestaurant.beverages);

    morganaRestaurant.courses = morganaRestaurant.courses.slice(0, idxToStart);
}

function setRedCafeMenu(redCafeRestaurant) {
    const idxToStart = redCafeRestaurant.beverages.findIndex(course => course.name === "bollicine");
    console.log({ idxToStart: idxToStart })
    redCafeRestaurant.wines = [];
    redCafeRestaurant.beverages.forEach((course, i) => {
        if (i >= idxToStart) {
            redCafeRestaurant.wines.push(course);
        }
    })
    redCafeRestaurant.beverages = redCafeRestaurant.beverages.slice(0, idxToStart);
    console.log({ redCafeRestaurant });
}

async function setSearchedItems(restaurant, searchedPyload) {
    // !!c'è del torbido attorno a questa funzione
    restaurant.filtered_items = searchedPyload;
    if (searchedPyload.dishes === null || Number(searchedPyload.dishes) === 0) { // undefined == null (?)
        delete restaurant.filtered_items;
    }
}

async function formatGmapsStreet(address_components, coordinates) {
    const componentForm = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
    };
    const addressObject = {
        streetNumber: "",
        streetName: "",
        cityName: "",
        stateAbbr: "",
        zipCode: "",
        coordinates: { latitude: coordinates.lat, longitude: coordinates.lng }
    };

    for (let i = 0; i < address_components.length; i++) {
        const addressType = address_components[i].types[0];
        if (componentForm[addressType]) {
            switch (addressType) {
                case "street_number":
                    addressObject.streetNumber = address_components[i].long_name;
                    break;
                case "route":
                    addressObject.streetName = address_components[i].long_name;
                    break;
                case "locality":
                    addressObject.cityName = address_components[i].long_name;
                    break;
                case "administrative_area_level_1":
                    addressObject.stateAbbr = address_components[i].short_name;
                    addressObject.state = address_components[i].long_name;
                    break;
                case "postal_code":
                    addressObject.zipCode = address_components[i].long_name;
                    break;
                case "country":
                    addressObject.countryAbbr = address_components[i].short_name;
                    addressObject.country = address_components[i].long_name;
                    break;
                default:
                    break;
            }
        }
    }
    return addressObject;
}

function formatPercentageToPrice(percentage, total) {
    console.log({ percentage, total })
    const x = (total * percentage) / 100;
    return total - x;
}

function replacer(matched) {
    return `<a className="underline" href="${matched}" target="_blank" rel="noopener noreferrer">${matched}</a>`
}

function legacyFilterFormatter(cia) {
    let { needs, demands } = cia;

    const dietsMap = { "1": 3, "2": 6, "3": 20, "4": 12, "5": 13, "6": 14, "7": 19 };
    const allergiesMap = { "1": 15, "2": 16, "3": 14, "4": 17, "5": 3, "6": 4, "7": 5, "8": 6, "9": 7, "10": 13, "11": 8, "12": 18, "13": 19, "14": 11, "15": 12 };

    return {
        allergies: needs.filter(need => "allergen" === need.type).map(need => ({ label: need.name, value: allergiesMap[String(need.id)] })),
        diets: needs.filter(need => "diet" === need.type).map(need => ({ label: need.name, value: dietsMap[String(need.id)] })),
        foods: demands.filter(demand => "aversion" === demand.type).map(demand => ({ label: demand.name, value: demand.id })),
    }
}

function chooseIconText(path) {
    let store = {
        "images/icons/menu/ic_tick_green.png": "piatto privo di glutine",
        "images/icons/menu/ic_cross_gray.png": "allergene rilevato",
        "images/icons/menu/ic_cross_orange.png": "allergene rilevato",
        "images/icons/menu/ic_tick_gray.png": "alimento non gradito rilevato",
        "images/icons/menu/ic_triangle_gray.png": "intolleranza alimentare rilevata",
        "images/icons/menu/ic_cross_yellow.png": "contaminazioni rilevate",
    }

    return store[path];
}

const formats = {
    isEven,
    shouldDisplayCta,
    shouldDisplayDishDetails,
    shouldDisplayDrinkDetails,
    shouldDisplayRestaurantCoverCharge,
    shouldDisplayPrice,
    shouldDisplayPayments,
    shouldDisplayPaymentsV2,
    shouldDisplayPaymentsButton,
    shouldDisplayPaymentsButtonV2,
    shouldDisplayFilterIcon,
    capitalize,
    cleanPrice,
    formatPrice,
    formatUrl,
    formatIngredients,
    formatDrinkName,
    formatDescription,
    formatMotuekaDescription,
    truncate,
    getDayOfWeek,
    shiftTime,
    formatIsoTime,
    getDeliveryRangeCost,
    setMorganaMenu,
    setRedCafeMenu,
    setSearchedItems,
    formatGmapsStreet,
    formatPercentageToPrice,
    replacer,
    legacyFilterFormatter,
    chooseIconText,
};
export default formats;
