// axiossss!
import axios from "axios";

export function redirectUri(type, id) {
    let redirect = new URL(window.location.origin);
    redirect.pathname = `${type}/q/${id}/auth/callback`;

    return redirect.toString();
}

export function requestUri(type, id) {
    let requestUri = new URL(process.env.REACT_APP_LOGIN_DOMAIN);
    requestUri.pathname = "oauth/authorize";
    requestUri.searchParams.append("client_id", process.env.REACT_APP_LOGIN_CLIENT_ID);
    requestUri.searchParams.append("redirect_uri", redirectUri(type, id));
    requestUri.searchParams.append("scope", process.env.REACT_APP_LOGIN_SCOPE);
    requestUri.searchParams.append("response_type", "code");
    requestUri.searchParams.append("state", "abc");

    return requestUri.toString();
}

export async function getToken(code, type, id) {
    let fd = new FormData();
    fd.append("grant_type", "authorization_code");
    fd.append("client_id", process.env.REACT_APP_LOGIN_CLIENT_ID);
    fd.append("client_secret", process.env.REACT_APP_LOGIN_CLIENT_SECRET);
    fd.append("redirect_uri", redirectUri(type, id));
    fd.append("code", code);

    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_LOGIN_DOMAIN}/oauth/token`,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export async function fetchUserInfo(accessToken) {
    return await axios({
        method: "get",
        url: `${process.env.REACT_APP_LOGIN_DOMAIN}/api/users/cia`,
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        }
    });
}

export async function logout() {
    return await axios({
        method: "POST",
        url: `${process.env.REACT_APP_LOGIN_DOMAIN}/logout`,
    });
}
