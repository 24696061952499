import React, { createContext, useContext } from "react";
import { fetchUserInfo, getToken, logout as apilogout, requestUri } from "../../logic/oauth";
import { useFilters } from "./filters-context";
import formats from "../formats";

const UserContext = createContext();

const localStorageKey = "__mycontactlessmenu_token__";

function UserProvider(props) {
    const { setFilters } = useFilters();

    const [user, setUser] = React.useState();
    const [cia, setCia] = React.useState();
    const [error, setError] = React.useState();

    async function authCallback(code, type, id) {
        try {
            let auth = await getToken(code, type, id);

            if (auth.status === 200) {
                let accessToken = auth.data.access_token;

                getUserInfo(accessToken);
            }
        } catch (err) {
            console.error(err);

            setError(err.response.data);
            localStorage.removeItem(localStorageKey);
        }
    }

    async function getUserInfo(accessToken) {
        let response = await fetchUserInfo(accessToken);

        console.log(response);

        if (response.status === 200) {
            const { cia, ...user } = response.data.data;

            localStorage.setItem(localStorageKey, accessToken);
            setUser(user);
            setCia(cia);
            setFilters(formats.legacyFilterFormatter(cia));
        }
    }

    async function logout() {
        //let response = await apilogout();

        //if (response.status === 200) {
        setUser(null);
        setCia(null);
        setFilters({ allergies: null, foods: null, diets: null });
        localStorage.removeItem(localStorageKey);
        //}
    }

    React.useEffect(() => {
        let token = localStorage.getItem(localStorageKey);
        if (token && !user) {
            getUserInfo(token);
        }
    }, []);

    const value = {
        getRequestUri: requestUri,
        user,
        cia,
        error,
        authCallback,
        logout,
    };

    return <UserContext.Provider value={value} {...props} />
}

function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUser }
