import React, { useState } from 'react';
import { chevronBackOutline } from 'ionicons/icons';
import { IonPage, IonContent, IonIcon, IonModal } from '@ionic/react';
import { useHistory } from "react-router";
import { useUser } from "../utils/contexts/user-context";
import UpdateAccountModalContent from "../components/modals/UpdateAccountModalContent";
import Cia from '../components/UI/Cia';

function ProfilePage() {
    const { cia, logout } = useUser();

    const [showAccountUpdateModal, setShowAccountUpdateModal] = useState(false);

    const onOpenAccountUpdateHandler = () => setShowAccountUpdateModal(true);
    const onCloseAccountUpdateHandler = () => setShowAccountUpdateModal(false);

    const history = useHistory();

    function backToMenu() {
        let params = new URLSearchParams(window.location.search);
        //console.log("PUSHING HISTORY ", params.keys(), params.get("type"), params.get("token"));
        history.push((params.get("type") && params.get("token")) ? `/${params.get("type")}/q/${params.get("token")}/menu` : "/");
    }

    const onLogoutHandler = () => {
        logout();
        backToMenu();
    }

    return (
        <IonPage>
            <IonContent>
                <section id="profile-page" className="container mx-auto py-6 px-2 font-sans">
                    <button className="back-button ml-4 mb-6 p-3 flex justify-center items-center rounded-full shadow-lg bg-white" onClick={backToMenu}>
                        <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                    </button>

                    <div className="">
                        {cia ? <Cia cia={cia} /> : null}

                        <div className="mt-8 px-4">
                            <a className="text-slate-500" href="https://login.mycia.it/cia" target="_blank" onClick={onOpenAccountUpdateHandler}>Modifica i tuoi dati</a><br />

                            <button className="mt-2 text-slate-500" onClick={onLogoutHandler}>Logout</button>
                        </div>
                    </div>
                </section>
            </IonContent>

            <IonModal cssClass="update-account-modal-trigger" mode="md" isOpen={showAccountUpdateModal} onDidDismiss={onCloseAccountUpdateHandler}>
                <UpdateAccountModalContent closerHandler={onCloseAccountUpdateHandler} />
            </IonModal>
        </IonPage>
    );
}

export default ProfilePage;
