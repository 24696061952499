import React, { useState } from 'react';
import coursesClient from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import dl from '../../utils/device-language';
import { useTranslation } from 'react-i18next';
import Dishes from './Dishes';
import { useFilters } from '../../utils/contexts/filters-context';

function TastingList(props) {
    const { course, index, tastingMenu } = props;

    const [products, setProducts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const { i18n } = useTranslation();
    const { filters } = useFilters();

    async function onOpening() {
        // populate products with course products
        // products are loaded from courses logic
        console.log("on opening");

        let clientCourse;

        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();


        clientCourse = await coursesClient.doGetTastingMenu(language, course.id, tastingMenu.id);

        setProducts(clientCourse.dishes);
        setLoading(false);
    }

    React.useEffect(() => { setOpen(false); }, [filters]);

    return (
        <Collapsible
            className="row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened setOpen={setOpen}></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    < Dishes dishes={products} index={index} isTastingMenu={tastingMenu !== undefined && tastingMenu !== null} /> // TODO: check
                }
            </div>
        </Collapsible>
    );
}

export default TastingList;
