import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { close } from "ionicons/icons";
import { useUser } from "../../utils/contexts/user-context";

function LoginSuggestion(props) {
    const { type, id } = props;

    const { getRequestUri } = useUser();

    const [show, setShow] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => {
            setShow(true)
        }, 1000);

        return () => { clearTimeout(timeout) }
    }, []);

    return (
        <div className="mb-4">
            <div className="w-full mb-4 p-2 rounded-lg border border-slate-300">
                <div className="mb-2 flex justify-between items-center">
                    <span className="py-1 px-2 rounded-full text-sm bg-mycia-red text-white">novita</span>

                    <button className="cursor-pointer flex items-center" onClick={() => setShow(false)}>
                        <IonIcon slot="icon-only" icon={close}></IonIcon>
                    </button>
                </div>

                <p className="text-sm text-center">Da oggi utilizza la tua <span className="underline font-bold">Carta d'Identità Alimentare</span>® nei menù digitali! Accedendo potrai vedere ogni menù di MyCIA filtrato secondo i tuoi gusti e preferenze. Clicca ora sul pulsante "Menù per te".</p>
            </div>

            <div className="cursor-pointer p-1 rounded-md bg-slate-100">
                <div className="w-full flex justify-center items-center">
                    <div className="flex-1 py-2 rounded-md font-bold text-sm text-center bg-gray-300 text-black">Menù completo</div>
                    <a href={getRequestUri(type, id)} className="flex-1 py-2 rounded-md font-bold text-center text-sm text-mycia-red">Menù per te</a>
                </div>
            </div>
        </div>
    );
}

export default LoginSuggestion;
