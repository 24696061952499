import React from "react";
import { IonModal } from "@ionic/react";
import { useUser } from "../../utils/contexts/user-context";
import { useFilters } from "../../utils/contexts/filters-context";
import LegendModalContent from "../modals/LegendModalContent";
import formats from "../../utils/formats";
import clsx from "clsx";

function FilterToggle(props) {
    const { cia } = useUser();
    const { resetFilters, setFilters } = useFilters();

    const [filterMenu, setFilterMenu] = React.useState(true);
    const [showLegendModal, setShowLegendModal] = React.useState(false);

    const onOpenLegendModalHandler = () => setShowLegendModal(true);
    const onCloseLegendModalHandler = () => setShowLegendModal(false);
    const onFullMenuHandler = () => {
        resetFilters();
        setFilterMenu(false);
    }
    const onPersonalMenuHandler = () => {
        setFilters(formats.legacyFilterFormatter(cia));
        setFilterMenu(true);
    }

    return (
        <div className="filter-modal-trigger my-4">
            <div className="cursor-pointer mb-2 p-1 rounded-md bg-slate-100">
                <div className="w-full flex justify-center items-center">
                    <button className={clsx({ "flex-1 py-2 rounded-md font-bold text-sm text-center": true, "bg-gray-300 text-black": !filterMenu, "text-gray-500": filterMenu })} onClick={onFullMenuHandler}>Menù completo</button>
                    <button className={clsx({ "flex-1 py-2 rounded-md font-bold text-sm text-center": true, "bg-mycia-red text-white": filterMenu })} onClick={onPersonalMenuHandler}>Menù per te</button>
                </div>
            </div>

            {filterMenu ? <p className="flex justify-end"><button onClick={onOpenLegendModalHandler} className="inline text-sm underline text-mycia-blue">legenda icone</button></p> : null}

            <IonModal cssClass="legend-modal-trigger" mode="md" isOpen={showLegendModal} onDidDismiss={onCloseLegendModalHandler}>
                <LegendModalContent closeHandler={onCloseLegendModalHandler} />
            </IonModal>
        </div >
    );
}

export default FilterToggle;
